import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  Switch,
  Typography,
  Space,
  Divider,
  Button,
  App,
} from 'antd';
import {
  BulbOutlined, PlusOutlined, YoutubeOutlined, MessageOutlined,
} from '@ant-design/icons';
import Table from 'src/components/Table';
import { useMyData } from 'src/hooks/useMyData';
import { useRedirect } from 'src/hooks/useRedirect';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import useFetchedData from 'src/hooks/useFetchedData';
import { useTheme } from '../../contexts/ThemeContext';
import InformationForm from './InformationForm';
import FinancialForm from './FinancialForm';
import DocsForm from './DocForm';
import TelegramButton from './TelegramButton';

const { Title } = Typography;

function Settings() {
  const { data, loading } = useMyData([
    'channels',
    'id',
    'information',
    'bankAccount',
    'telegramId',
    'docs',
    'name',
    'phoneNumber',
  ], {
    revalidateOnFocus: false,
  });
  const httpClient = useHttpClient();
  const redirect = useRedirect();
  const { modal, message } = App.useApp();

  const columns = [
    {
      title: 'عنوان ',
      key: 'title',
    },
    {
      key: 'url',
      title: 'آدرس',
      render: (value) => (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      ),
    },
    {
      key: 'actions',
      render: (_, { state, id, title }) => {
        if (!['MONETIZED', 'DEMONETIZED', 'ACCEPTED'].includes(state)) {
          return <Button size="small" onClick={handleDelete}>حذف</Button>;
        }

        function handleDelete() {
          modal.confirm({
            title: 'حذف کانال',
            content: `از حذف کانال ${title} اطمینان دارید؟`,
            onOk: () => httpClient.delete(`/channel/${id}`).then(() => {
              document.location.reload();
            }).catch((e) => {
              captureException(e);
              message.error('خطا هنگام حذف کانال، لطفا با پشتیبانی تماس بگیرید');
            }),
          });
        }
      },
    },
  ];

  const fetchFn = useCallback(
    async () => ({ data: data?.channels ?? [] }),
    [data],
  );
  const showForm = useMemo(() => data?.channels?.some((ch) => ['ACCEPTED', 'MONETIZED'].includes(ch.state)), [data]);
  const formDisabled = useMemo(() => data?.channels?.some((ch) => ch.state === 'MONETIZED'), [data]);

  if (loading) {
    return null;
  }
  function handleAddChannel() {
    httpClient
      .post('/auth/oauth2/permission', {
        permissions: ['youtube.readonly', 'youtubepartner-channel-audit'].join(
          ',',
        ),
      })
      .then(
        ({
          data: {
            data: { redirectUrl },
          },
        }) => {
          redirect(redirectUrl);
        },
      );
  }

  function renderForm() {
    if (!showForm) {
      return null;
    }
    return (
      <>
        <InformationForm data={data} disabled={formDisabled} />
        <FinancialForm data={data} disabled={formDisabled} />
        <DocsForm data={data} disabled={formDisabled} />
      </>
    );
  }

  return (
    <Space direction="vertical" size={50}>
      {
        [2, 3, 5, 14].includes(data?.id) && (
          <div>
            <Divider orientation="left" className="mt-5">
              <Space>
                <MessageOutlined />
                اتصال به تلگرام
              </Space>
            </Divider>
            <TelegramButton data={data} />
          </div>
        )
      }
      <div>
        <Divider orientation="left" className="mt-5">
          <Space>
            <YoutubeOutlined />
            کانال‌های متصل شده
          </Space>
        </Divider>
        <Table columns={columns} fetchFn={fetchFn} rowKey="title" pagination={false} />
        <Button
          icon={<PlusOutlined />}
          onClick={handleAddChannel}
          style={{ position: 'relative', top: 15 }}
        >
          افزودن کانال جدید
        </Button>
      </div>
      {renderForm()}
    </Space>
  );
}

export default Settings;
