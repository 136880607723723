import React, { useEffect } from 'react';
import {
  Divider,
  Form, Space, Typography,
} from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import { EMPTY_OBJECT } from 'src/constants';
import UploadImageInput from './UploadImageInput';
import FileInput from './FileInput';

function DocsForm({ data = EMPTY_OBJECT, disabled = false }) {
  const [form] = Form.useForm();
  const httpClient = useHttpClient();

  useEffect(() => {
    form.setFieldsValue(deserialize());
  }, [data]);

  function serializeForm() {
    const docs = form.getFieldsValue();
    return { docs };
  }

  function deserialize() {
    const { docs } = data;
    return docs;
  }
  function handleSubmit() {
    httpClient.put('/user/me', serializeForm()).catch(captureException);
  }
  return (
    <>
      <Divider orientation="left" className="mt-10">
        <Space>
          <FileImageOutlined />

          <Typography.Text>
            ارسال مدارک
          </Typography.Text>
        </Space>

      </Divider>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ layout: 'vertical' }}
        onValuesChange={handleSubmit}
        disabled={disabled}
      >
        <Space size={20}>
          <Form.Item
            label="عکس روی کارت ملی"
            rules={[
              { required: true, message: 'این فیلد ضروری است' },
            ]}
            name="nationalCardImage"
          >
            <FileInput />
          </Form.Item>
          <Form.Item
            label="عکس پشت کارت ملی"
            rules={[
              { required: true, message: 'این فیلد ضروری است' },
            ]}
            name="nationalCardBackImage"
          >
            <FileInput />
          </Form.Item>
        </Space>
      </Form>
    </>

  );
}

export default DocsForm;
