import React, {
  useContext, useEffect, useState, version,
} from 'react';
import {
  Button,
  ConfigProvider,
  Divider,
  Form, Input, Space, Typography,
} from 'antd';
import { BankOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import {
  getShebaBankName,
  isCardNumberValid, isShebaValid, filterToDigits, getCardBankName,
} from 'src/utils/digits';
import { AppContext } from 'src/constants/contexts';
import useRerender from 'src/hooks/useRerender';
import { EMPTY_OBJECT } from 'src/constants';

function FinancialForm({ data = EMPTY_OBJECT, disabled = false }) {
  const [form] = Form.useForm();
  const httpClient = useHttpClient();
  const [loading, setLoading] = useState(false);
  const { messageApi } = useContext(AppContext);
  const [changed, setChanged] = useState(false);
  const reRender = useRerender();

  function submitEnabled() {
    const hasError = form.getFieldsError().map(({ errors }) => errors).some((errors) => errors.length);
    if (hasError) {
      return false;
    }
    const d = deserialize();
    return changed && !Object.entries(form.getFieldsValue()).every(([key, value]) => d[key] === value);
  }

  useEffect(() => {
    form.setFieldsValue(deserialize());
    reRender();
  }, [data]);

  function serializeForm() {
    const { sheba, cardNumber } = form.getFieldsValue();
    return {
      bankAccount: {
        sheba: filterToDigits(sheba),
        cardNumber: filterToDigits(cardNumber),
        // number: toLatinDigit(number),
      },
    };
  }

  function getShebaPostFix() {
    const sheba = form.getFieldValue('sheba');
    if (!isShebaValid(sheba)) {
      return <WarningFilled />;
    }
    const name = getShebaBankName(sheba);
    if (!name) {
      return <CheckCircleFilled />;
    }
    return (
      <Space>
        <CheckCircleFilled />
        {name}
      </Space>
    );
  }
  function getCardPostFix() {
    const cardNumber = form.getFieldValue('cardNumber');
    if (!isCardNumberValid(cardNumber)) {
      return <WarningFilled />;
    }
    const name = getCardBankName(cardNumber);
    if (!name) {
      return <CheckCircleFilled />;
    }
    return (
      <Space>
        <CheckCircleFilled />
        {name}
      </Space>
    );
  }
  function deserialize() {
    const { bankAccount } = data;
    if (!bankAccount || Array.isArray(bankAccount)) {
      return EMPTY_OBJECT;
    }
    return bankAccount;
  }
  function handleSubmit() {
    setLoading(true);
    httpClient.put('/user/me', serializeForm())
      .finally(() => setLoading(false))
      .catch(captureException)
      .then(() => {
        setChanged(false);
        messageApi.open({
          type: 'success',
          content: 'تغییرات ذخیره شد',
        });
      });
  }
  return (
    <>
      {/* {messageContextHolder} */}
      <Divider orientation="left" className="mt-10">
        <Space>
          <BankOutlined />
          <Typography.Text>
            اطلاعات بانکی
          </Typography.Text>
        </Space>

      </Divider>
      <ConfigProvider direction="ltr">
        <Form
          layout="vertical"
          form={form}
          initialValues={{ layout: 'vertical' }}
          onFinish={handleSubmit}
          disabled={disabled}
        >
          <Form.Item dependencies={['cardNumber', 'sheba']}>
            {() => (
              <Form.Item
                validateFirst
                label="شماره شبا"
                name="sheba"
                rules={[
                  { required: true, message: 'این فیلد اجباری است' },
                  {
                    message: 'شماره شبا معتبر نیست',
                    validator: (_, value) => (value && !isShebaValid(value) ? Promise.reject() : Promise.resolve()),
                  },
                  {
                    message: 'متاسفانه در حال‌حاضر حساب بلوبانک پشتیبانی نمیشود!',
                    validator: (_, value) => (value && getShebaBankName(value) === 'بلو' ? Promise.reject() : Promise.resolve()),
                  },
                ]}
              >
                <Input
                  addonAfter={getShebaPostFix()}
                  style={{ direction: 'ltr', textAlign: 'left' }}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item dependencies={['cardNumber', 'sheba']}>
            {() => (
              <Form.Item
                validateFirst
                label="شماره کارت"
                name="cardNumber"
                rules={[
                  { required: true, message: 'این فیلد اجباری است' },
                  {
                    message: 'شماره کارت معتبر نیست',
                    validator: (_, value) => (value && !isCardNumberValid(value) ? Promise.reject(new Error('sdf')) : Promise.resolve()),
                  },
                  {
                    message: 'کارت و شبا متعلق به یک بانک نیست',
                    validator: (_, value) => {
                      const shebaBankName = getShebaBankName(form.getFieldValue('sheba'));
                      return shebaBankName && shebaBankName !== getCardBankName(value) ? Promise.reject() : Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  addonAfter={getCardPostFix()}
                  style={{ direction: 'ltr' }}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type={submitEnabled() ? 'primary' : 'default'}
                size="large"
                htmlType="submit"
                loading={loading}
              >
                ذخیره
              </Button>
            )}
          </Form.Item>
        </Form>
      </ConfigProvider>
    </>

  );
}

export default FinancialForm;
