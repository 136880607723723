import useSWR from 'swr';
import { useMemo } from 'react';
import { generateUrl } from 'src/utils/http';
import useHttpClient from './useHttpClient';

function useFetchedData(params, transformer = (d) => d) {
  const httpClient = useHttpClient({ baseURL: params?.baseURL });

  const url = params && typeof params === 'object' ? generateUrl(params) : params;

  const { data, ...res } = useSWR(
    url,
    (...args) => httpClient.get(...args).then((res) => res.data),
    params?.options,
  );

  const transformedData = useMemo(() => transformer(data), [data]);

  return {
    ...res,
    data: transformedData,
    loading: Boolean(params && !res.error && !data),
  };
}

export default useFetchedData;
