import { CheckCircleOutlined } from '@ant-design/icons';
import {
  Alert, Space, Tag, Typography,
} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash-es';
import useIsDesktop from 'src/hooks/useIsDesktop';
import UploadProvider from 'src/components/UploadProvider';
import VPNWarn from './VPNWarn';

function Header({ video, ...props }) {
  const isDesktop = useIsDesktop();
  const routes = [
    {
      path: '/',
      title: 'داشبورد',
    },
    {
      path: '/videos',
      title: 'ویدیوها',
    },
    {
      title: video?.title || video?.filename || video?.id,
    },
  ];

  function renderError() {
    if (video.state === 'NOT_PUBLISHED' && video?.errorMessage?.includes('quota')) {
      return (
        <Alert
          showIcon
          closable
          className="mb-4"
          message="محدودیت آپلود"
          description={(
            <div>
              به علت ظرفیت محدود و ترافیک بالا ویدیو شما در صف انتشار قرار گرفت و در ساعات آتی منتشر خواهد شد
              ،              تیم یوبر در تلاش است با افزایش منابع این تاخیر را به حداقل برساند

            </div>
          )}
          type="warning"
        />
      );
    }

    return null;
  }
  return (

    <>
      {renderError()}
      <Space {...props} wrap className={`${props.className || ''} justify-between w-full overflow-hidden`}>
        <Space
          style={{ maxWidth: '100%' }}
          split=" / "
        >
          {
            routes.map((r) => (
              r.path ? <Link to={r.path} key={r.title}>{r.title}</Link> : (
                <Typography.Text ellipsis key={r.title}>
                  {truncate(r.title, { length: isDesktop ? 30 : 20 })}
                </Typography.Text>
              )
            ))
          }
        </Space>

        {
          video.state === 'PUBLISHED' && (
            <Tag bordered={false} icon={<CheckCircleOutlined />} color="#87d068">
              منتشر شده
            </Tag>
          )
        }
        {
          video.fileState === 'UPLOAD_FAILED' && (
            <Tag bordered={false} icon={<CheckCircleOutlined />} color="#d0234a">
              خطا در آپلود
            </Tag>
          )
        }

      </Space>
      <VPNWarn />
      <UploadProvider.UploadStatus />
    </>

  );
}
export default Header;
