const KEY_PREFIX = '@strg';

Object.keys(localStorage).forEach((key) => {
  if (!key.startsWith(KEY_PREFIX)) {
    return;
  }
  const persistedValue = getParsedData(key);
  if (persistedValue === null || persistedValue?.value === null) {
    localStorage.removeItem(key);
  }
});

function getKey(key) {
  return `${KEY_PREFIX}/${key}`;
}
function set({ key, value, ttl }) {
  function attemptToSetItem(key, value, ttl) {
    localStorage.setItem(
      getKey(key),
      JSON.stringify({
        value,
        expireAt: ttl ? Date.now() + ttl : null,
      }),
    );
  }

  function clearOldestAndRetry(key, value, ttl) {
    const keys = Object.keys(localStorage)
      .filter((k) => k.startsWith(KEY_PREFIX))
      .sort((a, b) => {
        const itemA = getParsedData(a);
        const itemB = getParsedData(b);
        return (itemA?.expireAt || 0) - (itemB?.expireAt || 0);
      });

    for (let i = 0; i < keys.length; i += 1) {
      localStorage.removeItem(keys[i]);
      try {
        attemptToSetItem(key, value, ttl);
        console.log('Successfully cleared space and set new item.');
        return true;
      } catch (e) {
        if (!(e instanceof DOMException) || e.name !== 'QuotaExceededError') {
          throw e;
        }
      }
    }
    return false;
  }

  try {
    attemptToSetItem(key, value, ttl);
  } catch (error) {
    if (error instanceof DOMException && error.name === 'QuotaExceededError') {
      console.warn('localStorage quota exceeded. Attempting to clear old data.');
      if (!clearOldestAndRetry(key, value, ttl)) {
        console.error('Unable to set item even after clearing old data:', key);
      }
    } else {
      throw error;
    }
  }
}

function get(key) {
  try {
    const persistedValue = getParsedData(getKey(key));
    return persistedValue && persistedValue.value;
  } catch (e) {
    console.log(e);
    localStorage.removeItem(getKey(key));
    return null;
  }
}

function getParsedData(populatedKey) {
  const rawPersistedValue = localStorage.getItem(populatedKey);
  if (rawPersistedValue === null) {
    return null;
  }
  const persistedValue = JSON.parse(rawPersistedValue);
  if (
    persistedValue.expireAt && persistedValue.expireAt < Date.now()
  ) {
    return null;
  }
  return persistedValue;
}

export const JsonStorage = {
  get,
  set,
};

export async function cachePromiseResult(promise, { key, ttl = 0 } = {}) {
  const p = promise.then((value) => {
    JsonStorage.set({
      key,
      value,
      ttl,
    });
    return value;
  });
  if (JsonStorage.get(key)) {
    return JsonStorage.get(key);
  }
  return p;
}


