import React, {
  useContext, useMemo, useRef,
} from 'react';
import {
  CloudUploadOutlined,
  DollarOutlined,
  MessageOutlined,
  PoweroffOutlined,
  SettingOutlined,
  MoonOutlined,
  SunOutlined,
} from '@ant-design/icons';
import {
  App,
  Button, Layout, Menu, Progress, theme,
} from 'antd';
import {
  Outlet, useMatches,
} from 'react-router-dom';
import Router from 'src/constants/router';
import { AuthContext, UploadContext } from 'src/constants/contexts';
import usePersistedState from 'src/hooks/usePersistedState';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { useMyData } from 'src/hooks/useMyData';
import YoutubeConnect from 'src/components/YoutubeConnect';
import { useTheme } from 'src/contexts/ThemeContext';
import largeLogo from './logo-lg.svg';
import smallLogo from './logo-sm.svg';
import Login from './Login';

const { Header, Sider, Content } = Layout;
function useMenus() {
  const matchedRoutes = useMatches();
  const selectedKeyRef = useRef();
  const { progress, isUploading } = useContext(UploadContext);
  const { modal } = App.useApp();
  const { handleLogout } = useContext(AuthContext);

  // useRedirectEffect();

  const menus = useMemo(() => [

    // {
    //   path: '/videos',
    //   icon: <UnorderedListOutlined />,
    //   label: 'آپلودها',
    // },
    {
      path: '/income',
      icon: <DollarOutlined />,
      label: 'درآمد',
    },
    {
      path: '/videos',

      // icon: <CloudUploadOutlined />,
      // icon: <SyncOutlined spin style={{ position: 'absolute' }} />,
      // icon: <ActiveUploadIcon />,
      icon: isUploading ? (
        <Progress
          showInfo={false}
          type="circle"
          percent={progress.percent}
          size={16}
          strokeColor="red"
        />
      ) : <CloudUploadOutlined />,
      label: 'آپلود ویدیو',
    },
    {
      path: '/settings',
      icon: <SettingOutlined />,
      label: 'تنظیمات',
    },
    {
      path: '/support',
      icon: <MessageOutlined />,
      label: 'پشتیبانی',
      onClick: () => {
        window.open('https://t.me/Youber_admin');
      },
    },
    {
      path: '/logout',
      icon: <PoweroffOutlined />,
      label: 'خروج',
      onClick: () => {
        modal.confirm({
          icon: <CloudUploadOutlined />,
          title: 'خروج از حساب کاربری',
          content: 'از حساب کاربری خود خارج می‌شوید؟',
          onOk: handleLogout,
          okText: 'بله، خارج می‌شوم',
          cancelText: 'خیر',
        });
      },
    },
  ].map(({ path, onClick, ...m }) => ({
    ...m,
    onClick: (({ domEvent: e }) => {
      e.preventDefault();
      if (onClick) {
        onClick(e);
        return;
      }
      Router.navigate(path);
    }),
    key: path,
  })), [isUploading, progress?.percent]);

  const selectedKey = menus
    .map(({ key }) => key)
    .find((p) => matchedRoutes.some(({ pathname }) => pathname === p));
  selectedKeyRef.current = selectedKey || selectedKeyRef.current;

  return {
    menus,
    selectedKey: selectedKeyRef.current,
  };
}

function Panel() {
  const [collapsed, setCollapsed] = usePersistedState(() => window.innerWidth < 768, 'panel/collapsed');
  const { user } = useContext(AuthContext);
  const isDesktop = useIsDesktop();
  const { data: myData, loading: myDataLoading } = useMyData(['channels', 'id']);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { menus, selectedKey } = useMenus();
  // const siderWidth = !isDesktop && collapsed ? 60 : undefined;
  // console.log({ siderWidth });
  const { isDark, toggleTheme } = useTheme();

  if (!user) {
    return <Login />;
  }

  function renderContent() {
    if (myDataLoading) {
      return null;
    }
    if (!myData?.channels?.length) {
      return (
        <YoutubeConnect
          title="کانالی متصل نشده است"
          subTitle="جهت اتصال کانال روی دکمه زیر کلیک کنید"
          permissions={['youtube.readonly', 'youtubepartner-channel-audit']}
        />
      );
    }
    return <Outlet />;
  }

  const collapsedWidth = !isDesktop ? 40 : 80;
  return (
    <Layout style={{ minHeight: '100vh', width: 'auto' }}>
      <Sider
        // style={{ backgroundColor: colorBgContainer }}
        collapsible
        trigger={null}
        // width={siderWidth}
        collapsedWidth={collapsedWidth}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: colorBgContainer,
          boxShadow: 'rgb(0 0 0 / 11%) 0px 0px 10px 0px',
          // minWidth: siderWidth,
          // width: siderWidth,
        }}
        onClick={(e) => {
          if (e.defaultPrevented) {
            return;
          }
          setCollapsed((c) => !c);
        }}
      >
        <div style={{
          width: '100%',
          height: 80,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          {collapsed && <img src={smallLogo} className="max-h-full max-w-full p-2" alt="youber logo" /> }
          {!collapsed && <img src={largeLogo} className="max-h-full max-w-full p-4" alt="youber logo" />}
        </div>

        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          items={menus}
          style={{ border: 'none' }}
        />

        <div style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          // padding: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <Menu
            style={{ border: 'none' }}
            mode="inline"
            items={[{
              key: 'theme',
              icon: isDark ? <SunOutlined /> : <MoonOutlined />,
              label: isDark ? 'روشن' : 'تاریک',
              onClick: ({ domEvent: e }) => {
                e.stopPropagation();
                toggleTheme();
              },
            }]}
          />
        </div>
      </Sider>
      <Layout
        style={{
          transition: 'margin-right 0.2s ease',
          marginRight: collapsed ? collapsedWidth : 200,
          overflow: 'hidden',
        }}
      >
        <Content
          className="m-2 px-4 py-2 md:px-10 md:py-5"
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Panel;
