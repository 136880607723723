import { useState, useEffect } from 'react';
import { JsonStorage } from 'src/utils/storage';

function usePersistedState(initialValue, paramsOrKey) {
  let key; let
    ttl;

  if (typeof paramsOrKey === 'object') {
    ({ key, ttl } = paramsOrKey);
  } else {
    key = paramsOrKey;
  }

  if (!key) {
    throw new Error('Key is required for persisted states.');
  }

  const [state, setState] = useState(() => JsonStorage.get(key) || initialValue);

  useEffect(() => {
    setState(JsonStorage.get(key) || initialValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  useEffect(() => {
    JsonStorage.set({
      key,
      value: state,
      ttl,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, ttl]);

  return [state, setState];
}

export default usePersistedState;
