export function loadJs({
  url,
  onLoad = () => { },
}) {
  const script = document.createElement('script');
  script.onload = onLoad;
  script.src = url;

  document.head.appendChild(script);
}
