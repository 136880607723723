import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { Modal } from 'antd';
import { getQsParam } from 'src/utils/string';
import { AuthContext } from '../../constants/contexts';
import usePersistedState from '../../hooks/usePersistedState';
import { parseToken } from './utils';

const Modals = {
  LOGIN: 'LOGIN',
};

function AuthProvider({ children }) {
  const [user, setUser] = usePersistedState(null, 'auth-provider');
  // const [visibleModal, setVisibleModal] = useState(Modals.LOGIN);
  const [modal, contextHolder] = Modal.useModal();

  const handleLogout = useCallback(() => {
    modal.confirm({
      title: 'خرج از حساب',
      content: 'از حساب خود خارج می‌شوید؟',
      onOk(close) {
        setUser(null);
        close();
      },
      okText: 'بله',
      cancelText: 'خیر',
    });
  }, []);
  const handleToken = useCallback((token) => {
    setUser({ token, ...parseToken(token) });
  }, []);

  // handleToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwibmFtZSI6IlNhbWFuJTIwTW9oYW1hZGkiLCJyb2xlcyI6WyJST0xFX0FETUlOIl0sImlzVGVtcG9yYXJ5IjpmYWxzZSwiYXV0aG9yaXplZCI6WyJodHRwczovL3d3dy5nb29nbGVhcGlzLmNvbS9hdXRoL3lvdXR1YmUudXBsb2FkIl0sImlhdCI6MTY5MDkwOTIyMSwiZXhwIjoxNjkxMTY4NDIxfQ.5f4jZH8wDAi9VAlwBiA00A2uSx75BAhbn6C9qS-SryA');
  const contextValue = useMemo(() => ({
    user,
    handleToken,
    handleLogout,
    logout: () => setUser(null),
    isLoggedIn: Boolean(user),
  }), [user]);

  useEffect(() => {
    const token = getQsParam('auth_token');
    if (!token) {
      return;
    }
    setUser({ token, ...parseToken(token) });
    window.location.replace('/videos');
  }, []);

  return (
    <AuthContext.Provider value={contextValue} displayName="AuthProvider">
      {children}
      {contextHolder}
      {/* <LoginModal open={!user && visibleModal === Modals.LOGIN} onSuccess={setUser} /> */}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
