import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Col,
  Form, Input, Modal, Radio, Row, Select, Space,
} from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { LinkOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { useMyData } from 'src/hooks/useMyData';
import { useRedirect } from 'src/hooks/useRedirect';
import { UPLOAD_API_BASE_URL } from 'src/constants';
import { CategoryOptions } from './constants';
import PublishButton from './PublishButton';

const hasUploadPermission = (ch) => ch.scope.includes('youtube.upload');
function VideoForm({ video, onChange, disabled }) {
  const [form] = Form.useForm();
  const uploadHttpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const httpClient = useHttpClient();
  const isDesktop = useIsDesktop();
  const { data: { channels } = { channels: [] } } = useMyData(['channels']);
  const [modal, contextHolder] = Modal.useModal();
  const redirect = useRedirect();
  const saveForm = useCallback(() => {
    if (video.state === 'PUBLISHED') {
      return;
    }
    uploadHttpClient.put(`/video/${video.id}`, form.getFieldsValue())
      .catch(captureException)
      .then(onChange);
  }, [form, video]);

  const debouncedSaveForm = useDebouncedCallback(saveForm, 5000);

  const channelOptions = useMemo(() => channels
    .map(({ id: value, title: label }) => ({ value, label })), [channels]);

  function handleValuesChange(changedValues) {
    const formValues = form.getFieldsValue();
    if (formValues.privacyStatus !== 'private') {
      form.setFieldValue('publishedAt', null);
    }
    if (changedValues.channelId && !channels.some((ch) => ch.id === changedValues.channelId && hasUploadPermission(ch))) {
      modal.confirm({
        content: 'قابلیت آپلود برای این کانال فعال نشده است، فعال شود؟',
        onOk: () => httpClient.post('/auth/oauth2/permission', { permissions: 'youtube.upload' })
          .then(({ data: { data: { redirectUrl } } }) => {
            redirect(redirectUrl);
          }).catch((e) => {
            reset();
            captureException(e);
          }),
        onCancel: () => { reset(); },
        okText: 'بله',
        cancelText: 'فعلا نه!',
        icon: <LinkOutlined />,
      });
    }
    function reset() {
      form.setFieldValue('channelId', channels.find(hasUploadPermission)?.id);
    } debouncedSaveForm();
  }

  useEffect(() => {
    const {
      title, description, privacyStatus, keywords, category,
    } = video;
    form.setFieldsValue({
      title,
      description,
      privacyStatus,
      keywords,
      category,
      channelId: channels.find(hasUploadPermission)?.id,
    });
    saveForm();
  }, [channelOptions]);

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        form={form}
        initialValues={{ privacyStatus: 'private' }}
        onValuesChange={handleValuesChange}
        disabled={disabled}
      >
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item
              label="عنوان"
              name="title"
              rules={[{
                max: 100,
              }, {
                message: 'استفاده از کاراکترهای <> مجاز نمی‌باشد',
                validator: (_, value) => (/[<>]/.test(value) ? Promise.reject() : Promise.resolve()),
              },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="توضیحات"
              name="description"
              rules={[{
                max: 5000,
              }, {
                message: 'استفاده از کاراکترهای <> مجاز نمی‌باشد',
                validator: (_, value) => (/[<>]/.test(value) ? Promise.reject() : Promise.resolve()),
              },
              ]}

            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item label="وضعیت نمایش" name="privacyStatus">
              <Radio.Group name="privacyStatus">
                <Space direction={!isDesktop ? 'vertical' : 'horizontal'}>
                  <Radio value="public">عمومی</Radio>
                  <Radio value="private">خصوصی</Radio>
                  <Radio value="unlisted">عدم نمایش در لیست و سرچ</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {/* <Form.Item shouldUpdate>
              {() => (
                <Form.Item label="زمان انتشار" name="publishedAt">
                  <DatePicker
                    className="w-full"
                    changeOnBlur
                    placeholder="اکنون"
                    showTime
                    disabledDate={(d) => d.unix() * 1000 - Date.now() < -24 * 60 * 60 * 1000}
                    disabled={form.getFieldValue('privacyStatus') !== 'private'}
                    locale={{
                      ...locale,
                      lang: {
                        ...locale.lang,
                        ok: 'انتخاب',
                      },
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item> */}
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item name="channelId" label="کانال">
              <Select
                options={channelOptions}
                disabled={disabled || channelOptions.length <= 1}
              />
            </Form.Item>
            <Form.Item name="category" label="دسته‌بندی">
              <Select
                showSearch
                placeholder="جستجو"
                // optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={CategoryOptions}
              />
            </Form.Item>

            <Form.Item
              label="کلمات کلیدی"
              name="keywords"
              rules={[
                {
                  message: 'تعداد کلمات کلیدی بیش از حد مجاز است',
                  validator: (_, value) => (value.join('').length > 500 ? Promise.reject() : Promise.resolve()),
                },
                {
                  message: 'استفاده از کاراکترهای <> مجاز نمی‌باشد',
                  validator: (_, value) => (/[<>]/.test(value.join('')) ? Promise.reject() : Promise.resolve()),
                },
              ]}
              validateTrigger="onChange"
            >
              <Select
                mode="tags"
                style={{ width: '100%' }}
                open={false}
                allowClear
                suffixIcon={null}
              />
            </Form.Item>

          </Col>
        </Row>
      </Form>
      <PublishButton
        video={video}
        onChange={(...args) => {
          onChange(...args);
          saveForm();
        }}
      />
    </>

  );
}

export default VideoForm;

