import { captureException } from '@sentry/react';
import { Spin, message } from 'antd';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { OAUTH_CLIENT_ID } from 'src/constants';
import { AuthContext } from 'src/constants/contexts';
import useHttpClient from 'src/hooks/useHttpClient';
import { loadJs } from 'src/utils/script';

export default function GoogleLoginButton() {
  const containerRef = useRef(null);
  const httpClient = useHttpClient();
  const { handleToken } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    function handleCredentialResponse({ credential }) {
      setLoading(true);
      httpClient.post('auth/oauth2/login', {
        tokenId: credential,
      })
        .then(({ data: { data: { token } } }) => {
          messageApi.open({
            type: 'success',
            content: 'Welcome!',
          });
          setTimeout(() => {
            handleToken(token);
          }, 1000);
        })
        .catch((err) => {
          captureException(err);
          messageApi.open({
            type: 'error',
            content: 'خطایی رخ داد! کمی بعد دوباره امتحان کنید.',
          });
        })
        .finally(() => setLoading(false));
    }
    loadJs({
      url: 'https://accounts.google.com/gsi/client',
      // url: '/js/google-auth-client.js',
      onLoad: () => {
        window.google.accounts.id.initialize({
          client_id: OAUTH_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        window.google.accounts.id.renderButton(
          containerRef.current,
          {
            // theme: 'filled_blue',
            size: 'large',
            width: containerRef.current?.offsetWidth,
            // locale: 'fa-IR',
          },
        );
        // window.google.accounts.id.prompt(); // also display the One Tap dialog
      },
    });
  }, []);

  return (
    <>
      {contextHolder}
      {!loading ? (<div id="google-login-button" style={{ direction: 'ltr' }} ref={containerRef} />) : (<Spin />)}
    </>
  );
}
