import React from 'react';
import {
  Navigate,
  createBrowserRouter,
} from 'react-router-dom';
import Panel from 'src/pages/Panel';
import NewVideo from 'src/pages/NewVideo';
import EditVideo from 'src/pages/EditVideo';
import ListVideo from 'src/pages/ListVideo';
import ListWalletTransaction from 'src/pages/ListWalletTransaction';
import Settings from 'src/pages/Settings';

const Router = createBrowserRouter([
  {
    path: '/',
    Component: Panel,
    children: [
      {
        path: '/videos/new',
        Component: NewVideo,
      },
      {
        path: '/videos',
        Component: ListVideo,
      },
      {
        path: '/videos/:id',
        Component: EditVideo,
      },
      {
        path: '/income/',
        Component: ListWalletTransaction,
      },
      {
        path: '/settings',
        Component: Settings,
      },
      {
        path: '/',
        Component: ListVideo,
      },
    ],
  },
]);

export default Router;
