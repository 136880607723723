import {
  Button, Result, Table as BaseTable,
} from 'antd';
import React, {
  useCallback,
  useEffect, useRef, useState,
} from 'react';
import InboxOutlined from '@ant-design/icons/InboxOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import { debounce } from 'lodash-es';
import { TABLE_RENDER_TYPES } from './utils';
import { useColumns } from './useColumns';

function Table({
  columns,
  fetchFn,
  loading: loadingProp,
  empty,
  ...rest
}) {
  const normalizedColumns = useColumns(columns);
  const [loadingState, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [hasError, setHasError] = useState(false);
  const dataVersionRef = useRef(0);
  const [paginationConfig, setPaginationConfig] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
    showSizeChanger: true,
  });
  const loading = loadingProp || loadingState;

  const updateList = useCallback(debounce((params) => {
    setLoading(true);
    setHasError(false);
    dataVersionRef.current += 1;
    const dataVersion = dataVersionRef.current;
    fetchFn(params)
      .then(({ data, total, page }) => {
        // Todo: cancel prev requests.
        if (dataVersion !== dataVersionRef.current) {
          return;
        }
        // setRecords(data.map((r) => ({ ...r, setRecords })));
        setRecords(data);
        setPaginationConfig((currentPagination) => ({
          ...currentPagination,
          current: page ?? currentPagination.current,
          total,
        }));
        setLoading(false);
      }).catch((e) => {
        console.error(e);
        setLoading(false);
        setHasError(true);
      });
  }, 500), [fetchFn]);

  const renderEmpty = useCallback(() => {
    if (loading) {
      return null;
    }
    if (!hasError) {
      return empty;
    }
    return (
      <Result
        title="خطایی رخ داد"
        status="error"
        style={{
          marginTop: 20,
        }}
        icon={<ExclamationCircleOutlined />}
        extra={(
          <Button onClick={() => updateList({ pagination: paginationConfig })}>
            تلاش مجدد!
          </Button>
        )}
      />
    );
  }, [hasError, updateList, loading]);

  useEffect(() => {
    updateList({ pagination: paginationConfig });
  }, [updateList]);

  function handlePaginationChange(config) {
    setPaginationConfig(config);
    updateList({ pagination: config });
  }

  return (
    <BaseTable
      columns={normalizedColumns}
      dataSource={records}
      pagination={paginationConfig}
      onChange={handlePaginationChange}
      loading={loading || loading}
      locale={{
        emptyText: (renderEmpty()),
      }}
      {...rest}
    />
  );
}

Table.RenderTypes = TABLE_RENDER_TYPES;
Table.defaultProps = {
  empty: (
    <Result
      style={{
        marginTop: 20,
        filter: 'grayscale(1)',
        opacity: 0.3,
      }}
      icon={<InboxOutlined />}
      title="No data"
    />
  ),
};

export default Table;
