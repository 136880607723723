import React, { useEffect, useMemo } from 'react';
import { message } from 'antd';
import { AppContext } from 'src/constants/contexts';
import { getQsParam } from 'src/utils/string';
import usePersistedState from '../../hooks/usePersistedState';

function AppProvider({ children }) {
  const [channel, setChannel] = usePersistedState(null, 'app-provider/channel');
  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    const queryChannel = getQsParam('channel');
    if (queryChannel) {
      setChannel(+queryChannel);
    }
  }, []);

  const contextValue = useMemo(() => ({
    channel,
    setChannel,
    messageApi,
  }), [channel, messageApi]);

  return (
    <>
      {messageContextHolder}
      <AppContext.Provider value={contextValue} displayName="AppProvider">
        {children}
      </AppContext.Provider>
    </>

  );
}

export default AppProvider;
