import { useContext, useMemo } from 'react';
import { API_BASE_URL } from 'src/constants';
import { AuthContext } from 'src/constants/contexts';
import { httpServiceFactory } from 'src/utils/http';

function useHttpClient({ baseURL } = {}) {
  const { user, logout } = useContext(AuthContext);
  const service = useMemo(() => httpServiceFactory({
    getAuthorizationFn: () => (user ? `Bearer ${user.token}` : null),
    baseUrl: baseURL || API_BASE_URL,
  }), [user, baseURL]);
  service.interceptors.response.use((res) => res, (e) => {
    const { response } = e;
    if (response?.status === 401) {
      logout();
    }
    return Promise.reject(e);
  });
  return service;
}

export default useHttpClient;
