import {
  CheckCircleTwoTone,
  DeleteOutlined, PlusOutlined,
} from '@ant-design/icons';
import { captureException } from '@sentry/react';
import {
  App, Button, Flex, Progress, Upload,
} from 'antd';
import React, { useRef, useState } from 'react';
import useHttpClient from 'src/hooks/useHttpClient';

function FileInput({
  value, onChange, id,
}) {
  const [thumbnail, setThumbnail] = useState(null);
  const actionRef = useRef();
  const http = useHttpClient();
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();
  const [progress, setProgress] = useState(0);

  const handleChange = async ({ file }) => {
    if (file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (file.status === 'done') {
      const uploadUrl = new URL(actionRef.current);
      onChange(`${uploadUrl.origin}${uploadUrl.pathname || ''}`);
      setLoading(false);
      setProgress(0);
      setThumbnail(await getBase64(file.originFileObj));
    }
  };
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('حجم فایل باید کمتر از ۵ مگابایت باشد');
    }
    if (!isJpgOrPng || !isLt2M) {
      return Upload.LIST_IGNORE;
    }
    actionRef.current = await http.post('/user/me/docs', { filename: file.name, type: id }).then((res) => res.data.url);
    return Boolean(actionRef.current);
  };

  const uploadImage = async (options) => {
    const {
      onSuccess, onError, file,
    } = options;

    const config = {
      headers: {
        Authorization: undefined,
      },
      onUploadProgress: (event) => {
        setProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const res = await http.put(
        actionRef.current,
        file,
        config,
      );

      onSuccess('Ok');
    } catch (err) {
      onError({ err });
      captureException(err);
    }
  };
  function renderContent() {
    const imgUrl = thumbnail || (value && (typeof value === 'string') ? value : null);

    if (loading) {
      return (
        <Progress
          showInfo={false}
          type="circle"
          percent={progress.percent}
          size={32}
          strokeColor="red"
        />
      );
    }
    if (thumbnail) {
      return (
        <Flex
          align="center"
          style={{
            height: '100%',
            position: 'relative',
          }}
        >
          <img src={thumbnail} alt={id} style={{ width: '100%' }} />
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setThumbnail(null);
              onChange(null);
            }}
            shape="circle"
            icon={<DeleteOutlined />}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, 0)',
              opacity: 0.8,
            }}
          />
        </Flex>
      );
    }
    if (value) {
      return (
        <div>
          <CheckCircleTwoTone style={{ fontSize: 30 }} twoToneColor="#52c41a" />
          <div
            style={{
              marginTop: 8,
            }}
          >
            آپلود شده
          </div>
        </div>
      );
    }
    console.log({ img: imgUrl, thumbnail, value });

    return (
      <div style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>انتخاب فایل</div>
      </div>
    );
  }

  return (
    <Upload
      listType="picture-card"
      onChange={handleChange}
      beforeUpload={beforeUpload}
      showUploadList={false}
      onRemove={() => { }}
      accept="image/*"
      maxCount={1}
      multiple={false}
      customRequest={uploadImage}
    >
      {renderContent()}
    </Upload>
  );
}
const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export default FileInput;
