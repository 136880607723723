import {
  useCallback, useEffect, useMemo,
} from 'react';
import useDebouncedEffect from 'use-debounced-effect';
import { App } from 'antd';
import { captureException } from '@sentry/react';
import { useThrottledEffect } from 'use-throttled-effect';
import { debounce } from 'lodash-es';
import axios from 'axios';
import useHttpClient from 'src/hooks/useHttpClient';
import usePersistedState from 'src/hooks/usePersistedState';
import { UPLOAD_API_BASE_URL } from 'src/constants';

const wait = (ms) => new Promise((res) => { setTimeout(res, ms); });
const debouncedWarning = debounce((func) => {
  func();
}, 20000);

export function getFingerPrint(file) {
  return ['v8', file.type, file.size, CHUNK_SIZE].join('-');
}
const CHUNK_SIZE = 15 * 1024 * 1024;
const MAX_CONNECTIONS = 3;
const RETRY_WAITS = [2, 8, 32, 64];

function useUploader({
  file, video, setVideo, onError, clearFile,
}) {
  const videoId = video?.id;
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const [parts, setParts] = usePersistedState({}, `upload-parts/${videoId}`);
  const { modal, message } = App.useApp();
  const updatePart = useCallback((index, payload) => {
    setParts((current) => {
      const parts = { ...current };
      parts[index] = {
        ...parts[index],
        ...payload,
      };
      return parts;
    });
  }, []);

  useThrottledEffect(() => {
    if (!file) {
      Object.values(parts).forEach((p) => {
        if (p.controller && typeof p.controller.abort === 'function') {
          p.controller.abort();
        }
      });
    }
  }, 1000, [file, parts]);

  const completePart = useCallback(({ index, ETag }) => {
    httpClient.put(`/video/${videoId}/upload/${index}/complete`, { ETag })
      .then(({ data: { data } }) => setVideo(data))
      .catch(captureException);
  }, [videoId]);

  useDebouncedEffect(() => {
    Object.entries(parts).forEach(([index, { ETag }]) => {
      if (!ETag || !video?.s3?.parts?.[index]) {
        return;
      }
      if (video.s3.parts[index].ETag !== ETag) {
        completePart({ ETag, index });
      }
    });
  }, 3000, [parts, video, completePart]);

  const progress = useMemo(() => {
    if (!video?.size) {
      return { totalLoaded: 0, percent: 0 };
    }
    const totalLoaded = Object.entries(parts).reduce((acc, [index, part]) => {
      if (!part) {
        return acc;
      }
      if (part.ETag) {
        return acc + getChunkSize({ file, video, index });
      }
      if (part.loaded) {
        return acc + part.loaded;
      }
      return acc;
    }, 0);
    let percent;
    if (totalLoaded === video.size) {
      percent = Object.values(parts).every((p) => p.ETag) ? 100 : 99;
    } else {
      percent = video?.s3?.parts?.[0] ? (totalLoaded / video.size) * 100 : 0;
    }

    return { percent, totalLoaded };
  }, [parts, file, video]);

  useEffect(() => {
    if (progress.percent === 100) {
      clearFile();
    }
  }, [progress.percent, clearFile]);
  const uploadChunk = useCallback(({ part, chunk, index }) => {
    // console.log('upload chunk', { index, part });
    updatePart(index, { active: true });
    let retry = part.retry || 0;
    const controller = new AbortController();

    let abortTimeout;
    const options = {
      signal: controller.signal,
      onUploadProgress: (event) => {
        const { loaded } = event;
        clearTimeout(abortTimeout);
        abortTimeout = setTimeout(() => {
          // console.log('aborting');
          controller?.abort();
        }, 15000);
        retry = 0;
        updatePart(index, {
          controller,
          loaded,
          retry,
        });
      },
      headers: {
        'Content-Type': file.type,
        Authorization: undefined,
      },
    };
    httpClient
      .put(part.url, chunk, options)
      .then((res) => {
        clearTimeout(abortTimeout);
        const ETag = res.headers.etag.replaceAll('"', '');
        // console.log('done', ETag);
        // const ETag = ' ';
        updatePart(index, {
          active: false, ETag, updated: false, controller: undefined,
        });
        completePart({ ETag, index });
      })
      .catch((error) => {
        clearTimeout(abortTimeout);
        if (!axios.isCancel(error)) {
          captureException(error);
        }
        if (retry === 2) {
          debouncedWarning(() => {
            message.warning('در صورت امکان از اینترنت پر سرعت تری استفاده کنید!', 5);
          });
        }
        if (retry > RETRY_WAITS.length) {
          console.log(`Part#${index} failed to upload, giving up`);
          onError(new Error('خطا هنگام آپلود!'));
          return;
        }
        const backOffTime = RETRY_WAITS[retry];
        console.log(`Part#${index}/${retry} failed to upload, backing off ${backOffTime}s before retrying...`);
        wait(backOffTime * 1000).then(() => {
          updatePart(index, {
            retry: retry + 1,
            active: false,
          });
        });
      });
  }, [file, video?.id]);

  // init
  useEffect(() => {
    if (!file || !video?.id) {
      return;
    }
    if (video?.s3?.parts?.[0] && video.fingerprint && video.fingerprint === getFingerPrint(file)) {
      return;
    }
    if (video.fingerprint && video.fingerprint !== getFingerPrint(file)) {
      modal.confirm({
        title: 'ادامه آپلود',
        content: 'امکان ادامه آپلود وجود ندارد، از ابتدا آپلود شود؟',
        onOk: initializeUpload,
        onCancel: clearFile,
        okText: 'بله',
        cancelText: 'خیر',
      });
      return;
    }
    initializeUpload();

    function initializeUpload() {
      const partsCount = getChunksCount(file.size);
      return httpClient.post(`/video/${videoId}/upload/init`, {
        filename: file.name,
        fingerprint: getFingerPrint(file),
        partsCount,
        size: file.size,
      }).then((res) => {
        setVideo(res.data.data);
        setParts(res.data.data.s3.parts);
      }).catch(onError);
    }
  }, [file, video?.id, video?.fingerprint]);

  // init
  useEffect(() => {
    if (file || parts[0] || !video?.s3?.parts?.[0] || !video?.id) {
      return;
    }
    setParts(video.s3.parts);
  }, [parts.length, file, video?.id]);

  useDebouncedEffect(() => {
    if (!video?.id) {
      return;
    }
    if (video.fileState === 'UPLOADING' || progress.percent === 0 || progress.percent === 100) {
      return;
    }
    httpClient.put(`/video/${video.id}/file-state`, {
      fileState: 'UPLOADING',
    }).then(({ data: { data } }) => setVideo(data)).catch(captureException);
  }, 1000, [progress?.percent, video?.fileState]);

  // loop
  useEffect(() => {
    // console.log('parts', parts);
    if (!file) {
      return;
    }

    if (video.fingerprint && video.fingerprint !== getFingerPrint(file)) {
      return;
    }
    const activeConnections = Object.values(parts).filter((p) => p.active).length;

    // console.log('loop 1', activeConnections);
    if (activeConnections >= MAX_CONNECTIONS) {
      return;
    }

    const [index, part] = Object.entries(parts).find(([, p]) => !p.active && !p.ETag) || [];
    // console.log('loop 2', part);
    if (!part) {
      return;
    }
    const start = +index * CHUNK_SIZE;
    const chunk = file.slice(start, start + getChunkSize({ file, video, index }));
    uploadChunk(({ chunk, part, index }));
  }, [parts, uploadChunk, file, video?.id]);

  return progress;
}

function getChunkSize({ file, video, index }) {
  const fileSize = file?.size || video?.size;
  const chunkSize = video?.s3?.chunkSize || CHUNK_SIZE;
  const chunksCount = Object.keys(video?.s3?.parts)?.length || getChunksCount(fileSize);
  // console.log({ index, chunksCount });
  if (+index === chunksCount - 1) {
    return +index === 0 ? fileSize : fileSize - (+index * chunkSize);
  }
  return chunkSize;
}

function getChunksCount(fileSize) { return Math.max(Math.floor(fileSize / CHUNK_SIZE), 1); }

export default useUploader;
