import React, { useMemo } from 'react';
import { Typography } from 'antd';
import useIsDesktop from 'src/hooks/useIsDesktop';

export function useColumns(cols) {
  const isDesktop = useIsDesktop();

  return useMemo(() => cols.map((col) => {
    // eslint-disable-next-line prefer-const
    let { key, render, label } = col;

    const newRender = (value, ...args) => {
      const newValue = label && label[value] ? label[value] : value;
      return render ? render(newValue, ...args) : newValue;
    };
    const newCol = {
      ...col,
      key,
      render: newRender,
      dataIndex: key,
    };
    return isDesktop ? newCol : optimizeTableColumnsForMobile(newCol);
  }), [isDesktop, cols]);
}

export function optimizeTableColumnsForMobile(column) {
  if (!column.title) {
    return column;
  }
  return {
    ...column,
    render(...args) {
      const value = column.render ? column.render(...args) : args[0];
      if (!value) {
        return null;
      }
      return (
        <>
          <Typography.Text strong className="mx-1 cell-title" style={{ wordBreak: 'keep-all' }}>
            {column.title}
            :
          </Typography.Text>
          { value }
        </>
      );
    },
  };
}
