import { objectWithFallback } from 'src/utils/object';

export const VideoFileStateLabel = {
  NOT_UPLOADED: 'اپلود نشده',
  UPLOADING: 'درحال آپلود',
  UPLOADED: 'آپلود شده',
  UPLOAD_FAILED: 'خطا در آپلود',
  REMOVED: 'حذف شده',
};

export const VideoState = {
  WAITING_FOR_PUBLISH: 'منتشر نشده',
  NOT_PUBLISHED: 'منتشر نشده',
  PUBLISHING: 'درحال انتشار',
  PUBLISHED: 'منتشر شده',
  EXPIRED: 'منقضی شده',
};

export const VideoPrivacyStatusLabel = {
  public: 'عمومی',
  private: 'خصوصی',
  unlisted: 'عدم نمایش در لیست',
};
export const ChannelState = objectWithFallback({
  MONETIZED: 'فعال',
  UNKNOWN: 'عادی',
  REJECTED: 'عدم تایید',
  ACCEPTED: 'تایید اولیه',
  UNDER_REVIEW: 'درحال بررسی',
}, '-');
