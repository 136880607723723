import React, { useEffect } from 'react';
import { ConfigProvider, App as AntApp } from 'antd';
import {
  RouterProvider,
} from 'react-router-dom';
import FA_IR from 'antd/locale/fa_IR';
import AuthProvider from './components/AuthProvider';
import Router from './constants/router';
import AppProvider from './components/AppProvider';
import { getQsParam } from './utils/string';
import UploadProvider from './components/UploadProvider';

function App() {
  return (
    <ConfigProvider
      direction="rtl"
      locale={FA_IR}
      theme={{
        token: {
          fontFamily: "Yekanbakh, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
          colorPrimary: '#ff0000',
        },
      }}
    >
      <AuthProvider>
        <AppProvider>
          <AntApp>
            <InnerApp />
            <UploadProvider>
              <RouterProvider router={Router} />
            </UploadProvider>
          </AntApp>
        </AppProvider>
      </AuthProvider>
    </ConfigProvider>

  );
}

function InnerApp() {
  const ERRORS = {
    NO_CHANNEL_FOUND: 'کانال یوتیوبی به اکانت انتخاب شده متصل نیست، لطفا از این که اکانت درستی را انتخاب کرده‌اید اطمینان حاصل نمایید. درصورت حل نشدن مشکل با پشتیبانی تماس بگیرید.',
    NO_CHANNEL_CONNECTED: 'کانالی متصل‌ نشد! در صورتی که مراحل را به درستی طی کرده‌اید لطفا با پشتیبانی تماس بگیرید.',
    ACCESS_TOKEN_SCOPE_INSUFFICIENT: 'برای اتصال کانال باید دسترسی‌های خواسته شده را قبول کنید.',
  };
  const error = getQsParam('error');
  const { modal } = AntApp.useApp();
  useEffect(() => {
    if (error) {
      modal.error({
        title: 'خطا',
        content: ERRORS[error] || error,
      });
    }
  }, [error, modal]);

  return null;
}

export default App;
