import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form, Input, Row, Space, Typography,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import { filterToDigits, isPhoneNumberValid, toLatinDigit } from 'src/utils/digits';
import { AppContext } from 'src/constants/contexts';
import { EMPTY_OBJECT } from 'src/constants';

function InformationForm({ data = EMPTY_OBJECT, disabled = false }) {
  const [form] = Form.useForm();
  const httpClient = useHttpClient();
  const [loading, setLoading] = useState(false);
  const { messageApi } = useContext(AppContext);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    form.setFieldsValue(deserialize());
  }, [data]);

  function serializeForm() {
    const {
      phoneNumber, name, birthDate, ...information
    } = form.getFieldsValue();
    return {
      name,
      phoneNumber: filterToDigits(phoneNumber),
      information: {
        ...information,
        birthDate: toLatinDigit(birthDate),
        nationalCode: filterToDigits(information.nationalCode),
        nationalCardImage: undefined,
        nationalCardBacImage: undefined,
      },
    };
  }

  function deserialize() {
    const { phoneNumber, name, information } = data;
    return {
      name,
      phoneNumber,
      ...information,
    };
  }
  function submitEnabled() {
    const d = deserialize();
    return changed && !Object.entries(form.getFieldsValue()).every(([key, value]) => d[key] === value);
  }
  function handleSubmit() {
    setLoading(true);
    httpClient.put('/user/me', serializeForm())
      .then(() => {
        setChanged(false);
        messageApi.open({
          type: 'success',
          content: 'تغییرات ذخیره شد',
        });
      })
      .catch(captureException)
      .finally(() => setLoading(false));
  }
  return (
    <>
      <Divider orientation="left" className="mt-10">
        <Space>
          <UserOutlined />

          <Typography.Text>
            اطلاعات فردی
          </Typography.Text>
        </Space>

      </Divider>
      <Form
        layout="vertical"
        disabled={disabled}
        form={form}
        initialValues={{ layout: 'vertical' }}
        onFinish={handleSubmit}
        onValuesChange={() => {
          setChanged(true);
        }}

      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="نام"
              name="firstName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="نام خانوادگی"
              name="lastName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
                {
                  message: 'فقط حروف لاتین مجاز است',
                  validator: (_, value) => (/^[a-zA-Z\s]*$/.test(value) ? Promise.resolve() : Promise.reject()),
                },
              ]}
              label="نام به انگلیسی"
              name="firstNameEnglish"
            >
              <Input style={{ direction: 'ltr' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="نام خانوادگی به انگلیسی"
              name="lastNameEnglish"
            >
              <Input style={{ direction: 'ltr' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="آدرس"
              name="address"
            >
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="کد پستی"
              name="postalCode"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              label="کد ملی"
              name="nationalCode"
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
                {
                  message: 'کد ملی معتبر نیست',
                  validator: (_, value) => (!nationalCodeValidator(value) ? Promise.reject() : Promise.resolve()),
                },
              ]}
            >
              <Input style={{ direction: 'ltr' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="شماره همراه"
              name="phoneNumber"
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
                {
                  message: 'شماره همراه معتبر نیست',
                  validator: (_, value) => (!isPhoneNumberValid(value) ? Promise.reject() : Promise.resolve()),
                },
              ]}
            >
              <Input style={{ direction: 'ltr' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
              ]}
              label="نام پدر"
              name="fatherName"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
                {
                  pattern: /^[\d\u06F0-\u06F9\u0660-\u0669]{4}\/[\d\u06F0-\u06F9\u0660-\u0669]{2}\/[\d\u06F0-\u06F9\u0660-\u0669]{2}$/,
                  message: 'فرمت تاریخ باید به صورت YYYY/MM/DD باشد',
                },
              ]}
              label="تاریخ تولد"
              name="birthDate"
            >
              <Input placeholder="1370/01/01" style={{ direction: 'ltr' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type={submitEnabled() ? 'primary' : 'default'}
              size="large"
              htmlType="submit"
              loading={loading}
            >
              ذخیره
            </Button>
          )}
        </Form.Item>
      </Form>
    </>

  );
}

function nationalCodeValidator(c) {
  const code = toLatinDigit(c);
  if (code.length !== 10 || /(\d)(\1){9}/.test(code)) return false;

  let sum = 0;
  const chars = code.split('');

  for (let i = 0; i < 9; i++) sum += +chars[i] * (10 - i);

  const remainder = sum % 11;
  const lastDigit = remainder < 2 ? remainder : 11 - remainder;

  return +chars[9] === lastDigit;
}

export default InformationForm;
