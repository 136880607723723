import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Router from 'src/constants/router';
import usePersistedState from './usePersistedState';

export function useRedirectEffect() {
  const { path: prevPath, clear } = usePrevPath();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!prevPath || pathname === prevPath) {
      return;
    }
    clear();
    Router.navigate(prevPath);
  }, [prevPath]);
}

export function useRedirect() {
  const { savePath } = usePrevPath();

  return useCallback((url) => {
    savePath();
    window.open(url, '_blank');
  }, [savePath]);
}

function usePrevPath(url) {
  const { pathname } = useLocation();
  const [path, setPath] = usePersistedState(null, {
    key: 'use-prev-path/path',
    ttl: 1000 * 60,
  });

  return useMemo(() => ({
    path,
    clear: () => setPath(null),
    savePath: () => setPath(pathname),
  }), [pathname, path]);
}
