import React, { useContext, useState } from 'react';
import {
  Button,
} from 'antd';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import { UploadContext } from 'src/constants/contexts';
import { UPLOAD_API_BASE_URL } from 'src/constants';

function PublishButton({ onChange }) {
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const [loading, setLoading] = useState(false);
  const { isUploading, isUploaded, video } = useContext(UploadContext);

  function handlePublish() {
    setLoading(true);
    httpClient.put(`video/${video.id}/publish`).then(onChange)
      .catch(captureException)
      .finally(() => setLoading(false));
  }
  function handleUnpublish() {
    setLoading(true);
    httpClient.put(`video/${video.id}/unpublish`).then(onChange)
      .catch(captureException)
      .finally(() => setLoading(false));
  }

  if (video.state === 'PUBLISHED' || video.fileState === 'UPLOAD_FAILED') {
    return null;
  }

  if (video.state === 'PUBLISHING') {
    return (
      <Button loading disabled size="large">
        در حال انتشار
      </Button>
    );
  }

  if (video.state === 'WAITING_FOR_PUBLISH') {
    if (isUploaded) {
      return (
        <Button loading disabled size="large">
          در صف انتشار
        </Button>
      );
    }
    return (
      <Button loading={loading} icon={<CloseOutlined />} onClick={handleUnpublish} size="large">
        لغو انتشار
      </Button>
    );
  }
  return (
    <Button
      icon={<SendOutlined />}
      loading={loading}
      disabled={!isUploaded && !isUploading}
      type={isUploaded ? 'primary' : 'default'}
      onClick={handlePublish}
      size="large"
    >
      {isUploading ? 'انتشار پس از تکمیل آپلود' : 'انتشار' }
    </Button>
  );
}

export default PublishButton;
