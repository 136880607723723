import { Alert } from 'antd';
import React, { useState } from 'react';

function VPNWarn() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {
        visible && (
          <Alert
            banner
            showIcon
            closable
            className="my-2"
            message="بدون VPN آپلود پرسرعت‌تری را تجربه خواهید کرد!"
            type="warning"
          />
        )
      }
      <img src={`https://yt3.ggpht.com/ytc/AGIKgqN1F5HXRCFl48NA5bwfOJsdLakGKcwyJrcZ31fkGQ=s68-c-k-c0x00ffffff-no-rj?ts=${Date.now().toString().slice(0, -4)}`} alt="temp" onLoad={() => setVisible(true)} style={{ display: 'none' }} />
    </>
  );
}

export default VPNWarn;
