import React from 'react';

import {
  Button,
  Layout, Menu, Result, theme,
} from 'antd';

import Typography from 'antd/es/typography/Typography';
import GoogleLoginButton from 'src/components/GoogleLoginButton';

function Login() {
  return (
    <div
      className="h-screen w-screen flex items-center justify-center"
    >
      <Result
        icon={(
          <img
            src="/auth.gif"
            alt="login"
            height={300}
            style={{
              marginTop: -150,
              marginBottom: -70,
            }}
          />
        )}
        // status="success"
        title="لطفا با اکانت گوگل خود وارد شوید."
        // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
        extra={(
          <div className="w-full flex items-center justify-center flex-col gap-2">
            <GoogleLoginButton />
            <Typography.Paragraph>
              ورود به پنل به منزله قبول
              {' '}
              <a className="mx-1" href="https://youber.app/terms-of-use" target="_blank" rel="noreferrer">شرایط و قوانین</a>
              {' '}
              استفاده از سرویس یوبر می‌باشد.
            </Typography.Paragraph>
            <Button type="link" href="https://youber.app">بازگشت به سایت</Button>
          </div>
        )}
      />
    </div>

  );
}

export default Login;
