import React, { useCallback, useState } from 'react';
import {
  Button, Modal, Typography, Space, Divider,
  Spin,
  Skeleton,
} from 'antd';
import {
  CloseOutlined, PrinterOutlined, WalletOutlined,
} from '@ant-design/icons';
import { truncate } from 'lodash-es';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import Table from 'src/components/Table';
import { generateUrl } from 'src/utils/http';
import YoutubeConnect from 'src/components/YoutubeConnect';
import { useMyData } from 'src/hooks/useMyData';

const getColumns = (detailed) => [
  { key: 'id', title: 'شناسه' },
  // {
  //   title: 'تاریخ',
  //   key: 'paidAt',
  //   render: Table.RenderTypes.DATE,
  // },
  {
    key: 'amount',
    title: 'مبلغ',
    render: (value, { currency }) => (
      <Typography.Text
        code
        style={{ color: value > 0 ? 'green' : 'crimson' }}
      >
        {currency}
        {' '}
        {value > 0 ? '+' : null}
        {value}
        {' '}
        {/* {value > 0 ? 'واریز' : 'برداشت'} */}
      </Typography.Text>
    ),
  },
  {
    key: 'channel',
    title: 'کانال',
    render: (channel) => (channel ? (
      <Typography.Link href={channel.url} rel="noopener noreferrer" target="_blank">
        {channel.title}
      </Typography.Link>
    ) : '-'),
  },
  {
    key: 'currencyPriceInTMN',
    title: 'قیمت ارز به تومن',
    render: (value) => {
      if (!value) {
        return '-';
      }
      return (
        <Typography.Text>
          {Number(value).toLocaleString('fa-IR')}
          {' '}
          تومان
        </Typography.Text>
      );
    },
  },
  {
    key: 'irtAmount',
    title: 'مبلغ واریزی',
    render: (_, { amount, currencyPriceInTMN }) => {
      if (amount > 0) {
        return '-';
      }
      return currencyPriceInTMN ? `${Math.round(-1 * amount * currencyPriceInTMN).toLocaleString('fa-IR')} تومان` : '-';
    },
  },
  // { key: 'referenceCode', title: 'کد‌رهگیری', render: Table.RenderTypes.TAG },
  // {
  //   key: 'sheba', title: 'شبا', render: Table.RenderTypes.CODE, span: 3,
  // },
  {
    title: 'توضیحات',
    key: 'description',
    render: (value) => (
      <Typography.Text style={{ whiteSpace: 'pre' }}>
        {(detailed ? value : truncate(value, { length: 50 })).split('|').join('\n')}
      </Typography.Text>
    ),
  },

];
function ListWalletTransaction() {
  const httpClient = useHttpClient();
  const [selectedWithdraw, setSelectedWithdraw] = useState(null);
  const [hasRecord, setHasRecord] = useState(false);
  const { data: user, loading } = useMyData(['channels', 'gbpCredit']);

  const fetchFn = useCallback(
    ({ pagination: { current, pageSize } }) => httpClient.get(generateUrl({
      url: '/wallet-transaction',
      query: {
        _page: current,
        _page_size: pageSize,
        _sort: 'id',
        _order: 'DESC',
        _attributes: 'id,amount,channel,currencyPriceInTMN,currency,description,paidAt,channelId',
      // currency: 'USD',
      },
    }))
      .then(({ data: { data, total } }) => {
        if (total > 0) {
          setHasRecord(true);
        }
        return ({
          data,
          total,
        });
      })
      .catch(captureException),
    [],
  );

  if (!user || loading) {
    return <Skeleton paragraph={{ rows: 5 }} />;
  }
  if (!user.channels.length && !hasRecord) {
    return (
      <YoutubeConnect
        title="اتصال کانال"
        subTitle="جهت اتصال کانال روی دکمه زیر کلیک کنید"
        permissions={['youtube.readonly', 'youtubepartner-channel-audit']}
      />
    );
  }

  return (
    <>

      <Space className="mb-4">
        <WalletOutlined />
        <Typography.Text>اعتبار فعلی:</Typography.Text>
        <Typography.Text code>
          £
          {user?.gbpCredit || 0}
        </Typography.Text>
      </Space>

      <Divider orientation="left" className="mt-5">
        تراکنش ها
      </Divider>
      <Table
        columns={getColumns()}
        fetchFn={fetchFn}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            setSelectedWithdraw(record);
          },
        })}
        rowKey="id"
      />
      <DetailModal withdraw={selectedWithdraw} onClose={() => setSelectedWithdraw(null)} />
      <style>
        {'@media print {.print-hide,#root{display: none;}}'}
      </style>
    </>

  );
}

function DetailModal({ withdraw, onClose }) {
  const items = withdraw ? getColumns(true).map(({
    key, title: label, span = 1, render,
  }) => ({
    key, label, children: render ? render(withdraw[key], withdraw) : withdraw[key] || '-', span,
  })) : [];

  return (
    <Modal
      closeIcon={<CloseOutlined className="print-hide" />}
      footer={(
        <>
          <Button className="print-hide" icon={<PrinterOutlined />} onClick={() => window.print()}>پرینت</Button>
          <Button className="print-hide" onClick={onClose}>بستن</Button>

        </>
      )}
      open={Boolean(withdraw)}
      title="تراکنش"
      onCancel={onClose}
    >
      <Space wrap styles={{ item: { minWidth: '40%', padding: 4 } }}>
        {items.map(({ key, label, children }) => (
          <Space key={key} wrap>
            <Typography.Text>
              {label}
              :
            </Typography.Text>
            <Typography.Text strong>{children}</Typography.Text>
          </Space>
        ))}
      </Space>
    </Modal>
  );
}

export default ListWalletTransaction;
