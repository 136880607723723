import React, { useState } from 'react';
import {
  Button, Flex, Form, Input, Result,
} from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import { isPhoneNumberValid } from 'src/utils/digits';

function GetInfo({
  onUpdate,
  title = 'تکمیل اطلاعات',
  subTitle = 'شماره همراه شما ثبت نشده است، لطفا جهت ادامه شماره همراه خود را ثبت کنید.',
}) {
  const httpClient = useHttpClient();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function handleUpdateInfo() {
    setLoading(true);
    httpClient.put('/user/me', form.getFieldsValue())
      .then(() => onUpdate())
      .catch(captureException)
      .finally(() => setLoading(false));
  }

  return (
    <Result
      icon={<IdcardOutlined />}
      status="error"
      title={title}
      subTitle={subTitle}
      extra={[
        <Flex align="center" justify="center">
          <Form
            layout="vertical"
            style={{ width: 200 }}
            onFinish={handleUpdateInfo}
            form={form}
          >
            <Form.Item
              label="شماره همراه"
              name="phoneNumber"
              rules={[
                { required: true, message: 'این فیلد ضروری است' },
                {
                  message: 'شماره همراه معتبر نیست',
                  validator: (_, value) => (!isPhoneNumberValid(value) ? Promise.reject() : Promise.resolve()),
                },
              ]}
            >
              <Input style={{ direction: 'ltr' }} />
            </Form.Item>
            <Button
              // icon={<YoutubeFilled style={{ fontSize: 25 }} />}
              htmlType="submit"
              size="large"
              // onClick={handleUpdateInfo}
              style={{ width: '100%' }}
              type="primary"
              loading={loading}
              disabled={loading}
            >
              ثبت
            </Button>
          </Form>
        </Flex>,
      ]}
    />
  );
}

export default GetInfo;
