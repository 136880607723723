import React, { useEffect, useState } from 'react';
import { Button, Result } from 'antd';
import { DisconnectOutlined, YoutubeFilled } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
// import { useRedirect } from 'src/hooks/useRedirect';
// import ytLogo from './youtube.png';

function YoutubeConnect({
  permissions,
  title = 'این سرویس برای اکانت شما فعال نشده است',
  subTitle = 'جهت فعال‌سازی این سرویس روی دکمه زیر کلیک کنید.',
}) {
  const httpClient = useHttpClient();
  // const redirect = useRedirect();
  // const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    httpClient.post('/auth/oauth2/permission', { permissions: permissions.join(',') })
      .then(({ data: { data: { redirectUrl } } }) => {
        // redirect(redirectUrl);
        setUrl(redirectUrl);
      }).catch(captureException);
  }, []);

  return (
    <Result
      icon={<DisconnectOutlined />}
      status="error"
      title={title}
      subTitle={subTitle}
      extra={[
        // <img
        //   src={ytLogo}
        //   style={{
        //     cursor: 'pointer',
        //     width: 100,
        //   }}
        //   onClick={handleGetPermission}
        //   alt="sdf"
        // />,
        <Button
          href={url}
          icon={<YoutubeFilled style={{ fontSize: 25 }} />}
          size="large"
          // onClick={handleGetPermission}
          style={{ backgroundColor: '#FF0000' }}
          type="primary"
          // loading={loading}
          disabled={!url}
        >
          اتصال اکانت
        </Button>,
      ]}
    />
  );
}

export default YoutubeConnect;
