export const CategoryOptions = [
  { id: '1', title: 'فیلم و پویانمایی' },
  { id: '2', title: 'اتومبیل ها و وسایل نقلیه' },
  { id: '10', title: 'موسیقی' },
  { id: '15', title: 'حیوانات دست‌آموز و حیوانات دیگر' },
  { id: '17', title: 'ورزش' },
  { id: '19', title: 'سفر و رویدادها' },
  { id: '20', title: 'بازی‌' },
  { id: '22', title: 'افراد و وبلاگ‌ها' },
  { id: '23', title: 'کمدی' },
  { id: '24', title: 'سرگرمی' },
  { id: '25', title: 'اخبار و سیاست' },
  { id: '26', title: 'نحوه و روش انجام کار' },
  { id: '27', title: 'آموزش' },
  { id: '28', title: 'علم و فناوری' },
  { id: '29', title: 'غیرانتفاعی و فعالیت اجتماعی' },
].map((cat) => ({
  value: cat.id,
  label: cat.title,
}));
