import React, { useCallback, useContext } from 'react';
import {
  CaretRightOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined, LaptopOutlined,
} from '@ant-design/icons';
import {
  App,
  Button, Progress, Result, Typography,
} from 'antd';
import { useDropzone } from 'react-dropzone';
import { truncate } from 'lodash-es';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { UploadContext } from 'src/constants/contexts';

function UploadBox() {
  const uploadContext = useContext(UploadContext);
  const { modal, message } = App.useApp();
  const isDesktop = useIsDesktop();
  const {
    file, setFile, progress, video, isUploading, isUploaded, disabled,
  } = uploadContext;

  const onDrop = useCallback(([acceptedFile] = []) => {
    if (disabled) {
      return;
    }
    if (acceptedFile) {
      if (acceptedFile.size > 30 * 1024 * 1024 * 1024) {
        message.error('در حاحاضر امکان آپلود فایل‌های بزرگتر از ۳۰ گیگ نمی‌باشد.');
        return;
      }
      setFile(acceptedFile);
    }
  }, [disabled]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'video/*': ['.mkv'],
    },
  });

  const handleCancel = useCallback(() => {
    modal.confirm({
      icon: <CloudUploadOutlined />,
      title: 'لغو آپلود',
      content: 'از توقف فرآیند آپلود اطمینان دارید؟',
      onOk() {
        setFile(null);
      },
      okText: 'بله، متوقف شود',
      cancelText: 'خیر',
    });
  }, [video?.id, progress]);

  function renderContent() {
    if (file || video?.s3?.parts) {
      return (
        <div className="w-full">
          <div className="w-full gap-0 flex p-1 relative items-center">
            <Progress
              percent={isUploaded ? 100 : progress.percent}
              format={(percent) => (
                <code style={{
                  background: 'transparent',
                  fontSize: 12,
                  display: 'inline-block',
                  width: 25,
                }}
                >
                  {Math.floor(percent)}
                  %
                </code>
              )}
              // strokeLinecap="square"
              strokeColor={{ from: '#108ee9', to: '#87d068' }}
              size={['100%', 20]}
              style={{ flex: 1 }}
            />
            <code
              className="absolute"
              style={{
                left: 50,
                background: 'transparent',
                fontSize: 12,
                bottom: 13,
                color: 'gray',
              }}
            >
              {(progress.totalLoaded / (1024 * 1024)).toFixed(1)}
              Mb
            </code>
          </div>

          <div className="flex justify-between">
            {isUploading && (
              <Button
                size="small"
                onClick={handleCancel}
                icon={<CloseCircleOutlined />}
                danger
              >
                لغو آپلود
              </Button>
            )}
            <div className="flex gap-2">
              <Typography.Paragraph style={{ direction: 'ltr' }}>
                {truncate(video.filename || video.title || video.s3.key, { length: isDesktop ? 60 : 40 })}
              </Typography.Paragraph>

            </div>

          </div>
        </div>
      );
    }
  }

  function renderUploadBox() {
    if (!video || file || isUploaded || isUploading) {
      return null;
    }
    if (video?.s3?.parts) {
      return (
        <>
          <div {...getRootProps()} className="mb-4">
            <input {...getInputProps()} />
          </div>
          <Button
            type="primary"
            icon={<CaretRightOutlined />}
            size="large"
            className="mb-4"
            disabled={disabled}
            onClick={(e) => {
              modal.confirm({
                content: `برای ادامه آپلود باید فایل "${video.filename}" را دوباره انتخاب نمایید، اگر فایل دیگری را انتخاب کنید آپلود از ابتدا شروع می‌شود. `,
                onOk: () => getRootProps().onClick(e),
                okText: 'انتخاب فایل',
                cancelText: 'انصراف',
              });
            }}
          >
            ادامه آپلود
          </Button>

        </>

      );
    }
    return (
      <div
        style={{
          border: '1px dashed gray',
          borderRadius: 4,
        }}
        {...getRootProps()}
      >
        <Result
          title={isDesktop ? 'ویدیو را روی این قسمت رها کنید یا کلیک کرده و فایل را انتخاب کنید.' : ''}
          status="info"
          style={{
            marginTop: 20,
          }}
          icon={<CloudUploadOutlined style={{ color: 'gray' }} />}
          extra={(
            <>
              <input {...getInputProps()} />
              <Button
                type="primary"
                icon={<LaptopOutlined />}
                size="large"
              >
                {video?.s3?.parts ? 'انتخاب فایل جهت ادامه آپلود' : 'انتخاب فایل'}
              </Button>
            </>

          )}
        />
      </div>
    );
  }

  if (video.fileState === 'REMOVED') {
    return null;
  }

  return (
    <>
      <Typography.Text>فایل</Typography.Text>
      {renderUploadBox()}
      {renderContent()}
    </>
  );
}
export default UploadBox;
