import axios from 'axios';

export function httpServiceFactory({
  baseUrl,
  getAuthorizationFn,
  getUrlParamsFn,
}) {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      if (getAuthorizationFn && config.headers.Authorization == null) {
        config.headers = {
          Authorization: getAuthorizationFn(),
          ...config.headers,
        };
      }
      if (getUrlParamsFn) {
        const params = getUrlParamsFn();

        config.url = Object.entries(params).reduce((url, [key, value]) => url.replace(`{{${key}}}`, value), config.url);
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  return axiosInstance;
}

export const generateQuery = (params) => new URLSearchParams(params).toString();

export function generateUrl({ url, query }) {
  if (!query || Object.values(query).filter(Boolean).length === 0) {
    return url;
  }
  return `${url}?${generateQuery(query)}`;
}
