import { useContext } from 'react';
import { AuthContext } from 'src/constants/contexts';
import { EMPTY_OBJECT } from 'src/constants';
import useFetchedData from './useFetchedData';

export function useMyData(attributes = [], options = undefined) {
  const { user } = useContext(AuthContext);
  return useFetchedData(
    user && {
      url: '/user/me',
      query: {
        _attributes: attributes.join(','),
      },
      options,
    },
    ({ data } = EMPTY_OBJECT) => data,
  );
}
