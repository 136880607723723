export function toNumber(number) {
  if (!number) {
    return null;
  }

  return Number(
    toLatinDigit(number),
  );
}
export function toLatinDigit(str) {
  if (!str) {
    return '';
  }
  const ENGLISH_DIGITS = {
    '۱': '1', // Persian keyword
    '۲': '2',
    '۳': '3',
    '۴': '4',
    '۵': '5',
    '۶': '6',
    '۷': '7',
    '۸': '8',
    '۹': '9',
    '۰': '0',
    '١': '1', // Arabic Keyword
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0',
  };

  return str
    .toString()
    .split('')
    .map((digit) => (ENGLISH_DIGITS[digit]) || digit)
    .join('');
}

export function formatPrice(amount, discount) {
  return {
    amount: roundPrice(amount),
    beforeDiscount: roundPrice((100 * amount) / (100 - discount)),
  };
}

export function roundPrice(amount) {
  return Math.round(amount / 100) * 100;
}

export function isPhoneNumberValid(str) {
  const phone = filterToDigits(str);
  return /^[0-9]{10,12}$/.test(phone || '');
}
export function filterToDigits(iban) {
  if (!iban) {
    return iban;
  }
  return toLatinDigit(iban).replace(/[^0-9]/g, '');
}
export function isShebaValid(iban) {
  iban = `IR${filterToDigits(iban)}`;
  const objRegExp = /[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;
  if (!objRegExp.test(iban)) {
    return false;
  }
  let ibanNr = iban.substring(4) + iban.substring(0, 4);
  for (let nr = 10; nr <= 35; nr++) {
    ibanNr = ibanNr.replace(String.fromCharCode(nr + 55), nr.toString());
  }
  let currPart = '';
  let leftOver;
  for (let currDigit = 0; currDigit < ibanNr.length; currDigit++) {
    currPart += ibanNr.charAt(currDigit);
    const currNumber = parseInt(currPart, 10);
    if (currNumber >= 97) {
      leftOver = currNumber % 97;
      currPart = leftOver.toString();
    }
  }
  return leftOver === 1 || leftOver === 0;
}

export function getShebaBankName(iban) {
  const BANK_CODES = {
    '010': 'مرکزی',
    '011': 'صنعت و معدن',
    '012': 'ملت',
    '013': 'رفاه',
    '014': 'مسکن',
    '015': 'سپه',
    '016': 'کشاورزی',
    '017': 'ملّی',
    '018': 'تجارت',
    '019': 'صادرات',
    '020': 'توسعه صادرات',
    '022': 'توسعه تعاون',
    '021': 'پست بانک',
    '051': 'موسسه اعتباری توسعه',
    '053': 'کارآفرین',
    '054': 'پارسیان',
    '055': 'اقتصادنوین',
    '05606118': 'بلو',
    '056': 'سامان',
    '057': 'پاسارگاد',
    '058': 'سرمایه',
    '059': 'سینا',
    '060': 'مهر ایرانیان',
    '061': 'شهر',
    '062': 'آینده',
    '063': 'سپه',
    '064': 'گردشگری',
    '066': 'دی',
    '070': 'رسالت',
    '078': 'خاورمیانه',
    '069': 'ایران‌زمین',
  };
  if (!isShebaValid(iban)) {
    return null;
  }
  return Object.entries(BANK_CODES).find(([code]) => filterToDigits(iban)?.slice(2)?.startsWith(code))?.[1] || null;
  // return BANK_CODES[filterToDigits(iban)?.slice(2, 5)] || null;
}
export function getCardBankName(n) {
  if (!isCardNumberValid(n)) {
    return null;
  }
  return BANK_CARD_CODES[filterToDigits(n)?.slice(0, 6)] || null;
}

export function isCardNumberValid(str) {
  const n = filterToDigits(str);
  return /^[0-9]{16}$/.test(n || '');
}

const BANK_CARD_CODES = {
  502229: 'پاسارگاد',
  502806: 'شهر',
  502908: 'توسعه تعاون',
  502910: 'کارآفرین',
  502938: 'دی',
  504706: 'شهر',
  505416: 'گردشگری',
  585983: 'تجارت',
  585947: 'خاورمیانه',
  589210: 'سپه',
  589463: 'رفاه',
  603769: 'صادرات',
  603770: 'کشاورزی',
  603799: 'ملّی',
  606373: 'مهر ایرانیان',
  610433: 'ملت',
  621986: 'سامان',
  622106: 'پارسیان',
  627353: 'تجارت',
  627381: 'انصار',
  627412: 'اقتصادنوین',
  627488: 'کارآفرین',
  627648: 'توسعه صادرات',
  627760: 'پست بانک',
  627961: 'صنعت و معدن',
  628023: 'مسکن',
  628157: 'موسسه اعتباری توسعه',
  636214: 'آینده',
  636949: 'حکمت',
  639217: 'کشاورزی',
  639346: 'سینا',
  639347: 'پاسارگاد',
  639370: 'مهر اقتصاد',
  639599: 'قوامین',
  639607: 'سرمایه',
  991975: 'ملت',
  994147: 'رسالت',
  505172: 'رسالت',
  504172: 'رسالت',
  606256: 'عسکریه',
  505785: 'ایران زمین',
};
