import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { Alert, App } from 'antd';
import { useWakeLock } from 'react-screen-wake-lock';
import { captureException } from '@sentry/react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UploadContext } from 'src/constants/contexts';
import Router from 'src/constants/router';
import useHttpClient from 'src/hooks/useHttpClient';
import useIsDesktop from 'src/hooks/useIsDesktop';
import useFetchedData from 'src/hooks/useFetchedData';
import { EMPTY_OBJECT, UPLOAD_API_BASE_URL } from 'src/constants';
import useUploader from './useUploader';
import UploadBox from './UploadBox';

function UploadProvider({ children }) {
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState();
  const { modal } = App.useApp();
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });

  const { data: status, loading } = useFetchedData(
    {
      url: '/video/status',
      baseURL: UPLOAD_API_BASE_URL,
    },
    ({ data } = EMPTY_OBJECT) => data?.status,
  );

  const progress = useUploader({
    file,
    video,
    setVideo,
    clearFile: () => setFile(null),
    onError: (e) => {
      console.log(e);
      captureException(e);
      setFile(null);
      httpClient.put(`/video/${video.id}/file-state`, {
        fileState: 'UPLOAD_FAILED',
        errorMessage: e.message,
      }).then(({ data: { data } }) => setVideo(data)).catch((e) => {
        modal.error({
          title: 'خطا',
          content: e.message,
        });
      }).catch(captureException);
      modal.error({
        title: 'خطا',
        content: 'خطا هنگام آپلود فایل، لطفا کمی بعد دوباره امتحان کنید.',
      });
    },
  });
  const isUploading = Boolean(file && video && progress?.percent !== 100 && video.fileState !== 'REMOVED');
  const isUploaded = progress?.percent === 100 || video?.fileState === 'UPLOADED' || video?.state === 'PUBLISHED';
  const wakLock = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => console.log('An error happened 💥'),
    onRelease: () => console.log('Screen Wake Lock: released!'),
  });
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (!isUploading) {
      return;
    }
    const beforeUnloadHandler = (event) => {
      // Recommended
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    };
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  }, [isUploading]);

  useEffect(() => {
    if (!wakLock.isSupported || !isUploading) {
      return;
    }
    wakLock.request().then(console.log).catch((e) => {
      if (!isDesktop) {
        modal.warning({
          content: 'روشن بودن پاورسیوینگ ممکن از باعث وقفه در آپلود شود.',
        });
      }
      console.log(e);
      captureException(e);
    });

    return () => wakLock.release();
  }, [isUploading, isDesktop, modal]);

  // useEffect(() => {
  //   if (!video) {
  //     return;
  //   }
  //   const progressPercent = getVideoProgressPercent(video);
  //   if (progressPercent > 0 && progressPercent < 100) {

  //   }
  // }, [video]);

  const contextValue = useMemo(() => ({
    file,
    setFile,
    progress,
    video,
    setVideo,
    isUploading,
    isUploaded,
    loading,
    status,
    disabled: status === 'none_operational',
  }), [status, loading, file, progress, video, isUploading, isUploaded]);

  return (
    <UploadContext.Provider value={contextValue} displayName="UploadProvider">
      {children}
    </UploadContext.Provider>

  );
}

function UploadStatus() {
  const { status } = useContext(UploadContext);
  if (status === 'minor_incident') {
    return <Alert closable icon={<ExclamationCircleOutlined />} showIcon type="error" message="اختلال در زیرساخت شبکه" description="درحال‌حاضر به علت وجود اختلال در زیرساخت شبکه احتمال ناموفق بودن آپلود وجود دارد، در صورت بروز مشکل لطفا بعدا تلاش کنید." />;
  }
  return null;
}

UploadProvider.UploadBox = UploadBox;
UploadProvider.UploadStatus = UploadStatus;
export default UploadProvider;
