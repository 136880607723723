import React, { useRef, useState } from 'react';
import {
  CheckCircleOutlined, CheckCircleTwoTone, LoadingOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Modal, Upload, message,
} from 'antd';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});
// const getBase64 = (img, callback) => {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// };

function UploadImageInput({ onChange, value: imageUrl, id }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(false);
  const actionRef = useRef();
  // const [imageUrl, setImageUrl] = useState(value);
  const http = useHttpClient();
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    // console.log(file);
    // if (!file.url && !file.preview) {
    // file.preview = await getBase64(file.originFileObj);
    // }
    // setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = async ({ file }) => {
    if (file.status === 'uploading') {
      // onChange(null);
      setLoading(true);
      return;
    }
    if (file.status === 'done') {
      const uploadUrl = new URL(actionRef.current);
      onChange(`${uploadUrl.origin}${uploadUrl.pathname || ''}`);
      setLoading(false);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));

      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setLoading(false);
      //   // setImageUrl(url);
      //   onChange(url);
      // });
    }
  };
  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('حجم فایل باید کمتر از ۵ مگابایت باشد');
    }
    if (!isJpgOrPng || !isLt2M) {
      return Upload.LIST_IGNORE;
    }
    actionRef.current = await http.post('/user/me/docs', { filename: file.name, type: id }).then((res) => res.data.url);
    return Boolean(actionRef.current);
  };

  const uploadImage = async (options) => {
    const {
      onSuccess, onError, file, onProgress,
    } = options;

    const config = {
      headers: {
        Authorization: undefined,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    try {
      const res = await http.put(
        actionRef.current,
        file,
        config,
      );

      onSuccess('Ok');
    } catch (err) {
      onError({ err });
      captureException(err);
    }
  };

  const uploadButton = () => {
    if (loading || (previewImage && imageUrl)) {
    // if (loading) {
      return null;
    }
    if (imageUrl) {
      return (
        <div>
          <CheckCircleTwoTone style={{ fontSize: 30 }} twoToneColor="#52c41a" />
          <div
            style={{
              marginTop: 8,
            }}
          >
            آپلود شده
          </div>
        </div>
      );
    }
    return (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8,
          }}
        >
          آپلود
        </div>
      </div>
    );
  };
  return (
    <>
      <Upload
        // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        action={() => actionRef.current}
        listType="picture-card"
        onPreview={handlePreview}
        onChange={handleChange}
        accept="image/*"
        maxCount={1}
        multiple={false}
        beforeUpload={beforeUpload}
        onRemove={() => {
          onChange(null);
          setPreviewImage(null);
        }}
        id={id}
        method="put"
        customRequest={uploadImage}
        // showUploadList={false}
      >
        {uploadButton()}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
export default UploadImageInput;
