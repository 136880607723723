import React from 'react';
import { Tag, Typography } from 'antd';
import { EMPTY_OBJECT } from 'src/constants';

export const TABLE_RENDER_TYPES = {
  TAG: (value) => (
    <Tag>
      {value}
    </Tag>
  ),
  TAG_FACTORY({ color = EMPTY_OBJECT, icon = EMPTY_OBJECT, key }) {
    return function (value, { [key]: sourceValue }) {
      return (
        <Tag color={color[sourceValue]} icon={icon[sourceValue]}>
          {value}
        </Tag>
      );
    };
  },
  DATE: (value) => (value ? new Date(value).toLocaleDateString('fa-IR') : null),
  CODE: (value) => <Typography.Text code>{value}</Typography.Text>,
  AMOUNT: (value) => <Typography.Text strong code>{value}</Typography.Text>,
};
