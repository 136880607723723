import React, {
  useCallback, useContext, useEffect,
} from 'react';
import {
  Divider, Result, Space, Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { DatabaseOutlined } from '@ant-design/icons';
import useHttpClient from 'src/hooks/useHttpClient';
import { AuthContext, UploadContext } from 'src/constants/contexts';
import Router from 'src/constants/router';
import { EMPTY_OBJECT, UPLOAD_API_BASE_URL } from 'src/constants';
import UploadProvider from 'src/components/UploadProvider';
import VideoForm from './Form';
import Header from './Header';

function EditVideo() {
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { video, setVideo, disabled } = useContext(UploadContext);
  const isDisabled = video?.state === 'PUBLISHED' ||
    video?.state === 'PUBLISHING' ||
    (
      video?.fileState === 'UPLOADED' && video?.state === 'WAITING_FOR_PUBLISH'
    ) || video?.fileState === 'REMOVED';
  const onVideoUpdateRes = useCallback(
    (res) => {
      if (!res) {
        return;
      }
      // console.log(res);
      const { data: { data } = EMPTY_OBJECT } = res;
      setVideo(data);
    },
    [],
  );

  useEffect(() => {
    if (!id || !user) {
      return;
    }
    function fetchVideo() {
      httpClient.get(`/video/${id}`)
        .then(onVideoUpdateRes)
        .catch((e) => {
          if (e.response?.status === 404) {
            Router.navigate('/videos');
          }
          captureException(e);
          console.log(e);
        });
    }
    fetchVideo();
    if (!video || !['WAITING_FOR_PUBLISH', 'PUBLISHING'].includes(video.state) || video.fileState !== 'UPLOADED') {
      return;
    }
    const interval = setInterval(fetchVideo, 5000);
    return () => clearInterval(interval);
  }, [user, video?.state, video?.fileState]);

  if (!video) {
    return null;
  }
  if (disabled) {
    return (
      <Result
        status="warning"
        icon={<DatabaseOutlined />}
        title={(
          <>
            <Typography.Title level={5}>سرویس آپلود جهت بروز رسانی فعلا در دسترس نیست!</Typography.Title>
            <Typography.Paragraph> به زودی برمیگردیم :)</Typography.Paragraph>
          </>
        )}
      />
    );
  }
  return (
    <>
      <Header video={video} className="mb-4" />
      <Space direction="vertical" className="w-full">
        <UploadProvider.UploadBox />
        <Divider />
        <VideoForm video={video} onChange={onVideoUpdateRes} disabled={isDisabled} />
      </Space>
    </>

  );
}
export default EditVideo;
