import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Empty, Result, Spin,
} from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import Router from 'src/constants/router';

import YoutubeConnect from 'src/components/YoutubeConnect';
import { useMyData } from 'src/hooks/useMyData';
import { generateUrl } from 'src/utils/http';
import GetInfo from 'src/components/GetInfo';
import { UploadContext } from 'src/constants/contexts';
import { UPLOAD_API_BASE_URL } from 'src/constants';

function NewVideo() {
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const { data: user, loading, mutate } = useMyData(['channels', 'phoneNumber']);
  const hasPermission = user?.channels?.some?.((ch) => ch.scope.includes('youtube.upload'));
  const [unpublishedVideo, setUnpublishedVideo] = useState();
  const { video: uploadingVideo, isUploading, setVideo } = useContext(UploadContext);
  useEffect(() => {
    if (!hasPermission || !user?.phoneNumber) {
      return;
    }
    httpClient.get(generateUrl({
      url: '/video',
      query: {
        _page_size: 2,
        _sort: 'id',
        _order: 'DESC',
        _attributes: 'id,s3,filename,title,state,fileState',
      },
    })).then(({ data: { data: videos } }) => {
      const video = videos.find((v) => v.state !== 'PUBLISHED');
      if (video) { setUnpublishedVideo(video); } else (createVideo());
    }).catch(captureException);
  }, [hasPermission, user?.phoneNumber]);

  if (isUploading) {
    Router.navigate(`/videos/${uploadingVideo.id}`);
    return null;
  }

  function createVideo() {
    setVideo(null);
    return httpClient.post('video').then((res) => Router.navigate(`/videos/${res.data.data.id}`)).catch(captureException);
  }
  // return (
  //   <Result
  //     icon={<HddOutlined />}
  //     title={(
  //       <div>
  //         سرویس آپلود جهت ارتقا موقتا خاموش می‌باشد
  //         <br />
  //         به زودی برمیگردیم :)
  //       </div>
  //     )}
  //   />
  // );
  if (!loading && !user?.phoneNumber) {
    return (
      <GetInfo onUpdate={mutate} />
    );
  }
  if (!loading && !hasPermission) {
    return (
      <YoutubeConnect permissions={['youtube.upload']} />
    );
  }
  if (unpublishedVideo) {
    if (!unpublishedVideo.filename) {
      Router.navigate(`/videos/${unpublishedVideo.id}`);
      return <Empty image={<Spin size="large" />} description="درحال بارگذاری" />;
    }
    return (
      <Result
        icon={<FileSearchOutlined />}
        status="info"
        title="آپلود ناتمام"
        subTitle={`یک فایل آپلود نشده به اسم "${unpublishedVideo.filename}" از قبل وجود دارد، آن را ادامه می‌دهید؟`}
        extra={[
          <Button key="1" className="my-1" type="primary" size="large" onClick={() => Router.navigate(`/videos/${unpublishedVideo.id}`)}>ادامه آپلود</Button>,
          <Button key="2" className="my-1" size="large" onClick={createVideo}>ایجاد آپلود جدید </Button>,
        ]}
      />
    );
  }
  return (
    <Empty image={<Spin size="large" />} description="درحال بارگذاری" />
  );
}

export default NewVideo;
