import { MessageOutlined } from '@ant-design/icons';
import {
  Button,
} from 'antd';
import React,
{ useEffect, useState } from 'react';
import { EMPTY_OBJECT } from 'src/constants';
import useHttpClient from 'src/hooks/useHttpClient';

function TelegramButton({ data = EMPTY_OBJECT }) {
  const httpClient = useHttpClient();
  const [token, setToken] = useState('');

  useEffect(() => {
    httpClient.post('/auth/ephemeral-token/').then(({ data: { data: { token } } }) => {
      setToken(token);
    });
  }, [data, httpClient]);

  return (
    <Button
      href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT_NAME}?start=${token}`}
      disabled={!token || data?.telegramId}
      target="_blank"
      rel="noreferrer noopener"
      icon={<MessageOutlined />}
      size="large"
    >

      {data.telegramId ? 'متصل شده' : 'اتصال اکانت تلگرام'}
    </Button>
  );
}

export default TelegramButton;
