import React, { useCallback, useContext } from 'react';
import {
  Button, Divider, Result, Space, Typography,
} from 'antd';
import {
  CheckCircleOutlined, CloudUploadOutlined, EyeInvisibleOutlined, EyeOutlined, FieldTimeOutlined, FolderOpenOutlined, WarningOutlined,
} from '@ant-design/icons';
import { truncate } from 'lodash-es';
import { captureException } from '@sentry/react';
import useHttpClient from 'src/hooks/useHttpClient';
import Table from 'src/components/Table';
import { generateUrl } from 'src/utils/http';
import Router from 'src/constants/router';
import { VideoPrivacyStatusLabel, VideoState } from 'src/constants/strings';
import { getVideoProgressPercent } from 'src/utils/video';
import { UploadContext } from 'src/constants/contexts';
import { UPLOAD_API_BASE_URL } from 'src/constants';

function ListVideo() {
  const httpClient = useHttpClient({ baseURL: UPLOAD_API_BASE_URL });
  const { video: uploadingVideo, isUploading } = useContext(UploadContext);
  const fetchFn = useCallback(({ pagination: { current, pageSize } }) => httpClient.get(generateUrl({
    url: '/video',
    query: {
      _page: current,
      _page_size: pageSize,
      _sort: 'id',
      _order: 'DESC',
    },
  })).then(({ data: { data, total } }) => ({
    data,
    total,
  })).catch(captureException), []);

  if (isUploading) {
    Router.navigate(`/videos/${uploadingVideo.id}`);
    return null;
  }

  const columns = [
    { key: 'id', title: 'شناسه' },
    { key: 'title', title: 'عنوان', render: (title, { filename }) => truncate(title || filename, { length: 30 }) },
    {
      key: 'state',
      title: 'وضعیت',
      label: VideoState,
      render: Table.RenderTypes.TAG_FACTORY({
        key: 'state',
        color: {
          PUBLISHED: 'green',
        },
        icon: {
          PUBLISHED: <CheckCircleOutlined />,
          WAITING_FOR_PUBLISH: <FieldTimeOutlined />,
        },
      }),

    },
    {
      key: 'progress',
      title: 'درصد پیشرفت',
      render: (_, v) => {
        let percent;
        const { state } = v;
        if (state === 'PUBLISHED') {
          percent = '100%';
        }
        percent = `${getVideoProgressPercent(v)}%`;
        return <Typography.Text code>{percent}</Typography.Text>;
      },
    },
    {
      key: 'privacyStatus',
      title: 'نوع نمایش',
      // render: Table.RenderTypes.TAG,
      render: Table.RenderTypes.TAG_FACTORY({
        key: 'privacyStatus',
        color: {
          public: 'blue',
          private: 'red',
        },
        icon: {
          public: <EyeOutlined />,
          private: <EyeInvisibleOutlined />,
        },
      }),
      label: VideoPrivacyStatusLabel,
    },
    // {
    //   title: 'وضعیت فایل',
    //   key: 'fileState',
    //   render: Table.RenderTypes.TAG_FACTORY({
    //     key: 'fileState',
    //     color: {
    //       UPLOADED: 'green',
    //       UPLOAD_FAILED: 'error',
    //     },
    //     icon: {
    //       UPLOAD_FAILED: <WarningOutlined />,
    //       REMOVED: <WarningOutlined />,
    //     },
    //   }),
    //   label: VideoFileStateLabel,
    // },
    {
      title: 'تاریخ ایجاد',
      key: 'createdAt',
      render: Table.RenderTypes.DATE,
    },
  ];

  return (
    <>
      <Space className="mb-4">
        <Button
          type="primary"
          icon={<CloudUploadOutlined />}
          size="large"
          onClick={() => Router.navigate('/videos/new')}
        >
          آپلود ویدیو
        </Button>
      </Space>

      <Divider orientation="left" className="mt-5">
        آپلود‌های قبلی
      </Divider>
      <Table
        columns={columns}
        fetchFn={fetchFn}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            Router.navigate(`/videos/${record.id}`);
          },
        })}
        rowKey="id"
        empty={(
          <Result
            title="هنوز ویدویی آپلود نکردید"
            status="info"
            style={{
              marginTop: 20,
            }}
            icon={<FolderOpenOutlined style={{ color: 'gray' }} />}
            extra={(
              <Button
                type="primary"
                icon={<CloudUploadOutlined />}
                size="large"
                onClick={() => Router.navigate('/videos/new')}
              >
                آپلود ویدیو
              </Button>
            )}
          />
        )}
      />
    </>

  );
}

export default ListVideo;
